'use strict';
var personalization = {};

// Opens the Personalization Modal, using query params for Product-Personalization which
// populates the modal body
personalization.triggerPersonalizationModal = function() {
    $(document).ready(function() {
        $('.personalize').click(function() {
            var pid = $(this).attr('data-pid');
            var customizationCode = $(this).attr('data-code');
            var editUuid = $(this).attr('data-edit-uuid');
            var url = $(this).data('url') + '?pid=' + pid;
            if (customizationCode) {
                url = url.concat("&code=", customizationCode);
            }
            if (editUuid) {
                url = url.concat("&editUuid=", editUuid);
            }
            
            $.ajax({
                url: url,
                type: 'get',
                success: function(response) {
                    $('#personalizationModal .modal-body').html(response);
                    $('#personalizationModal').modal('show');
                    //Edit in cart
                    if(editUuid) {
                        $('body').trigger('personalization:refreshPreview');
                    }
                    $('body').trigger('personalization:modalReady');
                },
                error: function(xhr, status, error) {
                    console.error("Error loading personalization options for code (" + customizationCode  + "): ", error);
                }
            });
        });
    });
}

// For Edit in Cart, refreshes the preview window with the prepoluated input values
personalization.refreshPreview = function() {
    $('body').on('personalization:refreshPreview', function() {
        var $inputs = $('.js-customization-input');
        $inputs.each(function(index) {
            personalization.updatePreviewFromInput($(this), index);
        });
    })
}

function updatePersonalizationButton(product, $personalizationButton) {
    $personalizationButton.attr('data-pid', product.id);
    $personalizationButton.attr('data-code', product.customizationCode);
    $personalizationButton.html(product.customizationButtonCta);
    $personalizationButton.removeClass('d-none');
    if (product.available) {
        $personalizationButton.attr('disabled', false);
    }
}

//Updates Personalization button on attribute select
personalization.updatePersonalizationButton = function() {
    $('body').on('product:afterAttributeSelect product:statusUpdate', function (event, response) {
        var $modal = $('#personalizationModal');
        var product;
        if (event.type == 'product:statusUpdate') {
            product = response;
        } else {
            product = response.data.product;
        }
        var $actionButtonsContainer = $('.prices-add-to-cart-actions');
        var $personalizationButton = $actionButtonsContainer.find('button.personalize');
        var $atcButton = $actionButtonsContainer.find('button.add-to-cart');
        var $buttonDivider = $actionButtonsContainer.find('.addtocart-cta-divider');
        var showAddToCart = !product.customizationRequired;
        var showPersonalizaton = product.customizationCode || false;

        $modal.find('.modal-body').empty();
        
        if (showAddToCart) {
            $atcButton.removeClass('d-none');
        } else {
            $atcButton.addClass('d-none');
        }
        
        if (showPersonalizaton) {
            if (product.customizationCode !== 'COUP') {
                updatePersonalizationButton(product, $personalizationButton);
            } else {
                var $giftCertOptions = $('div[data-option-id="GiftCertificateOption"] select.options-select');
                var $selectedOption = $giftCertOptions.find('option[selected]');
                var selectedGiftCertValue = $selectedOption.attr('data-value-id');
                if (selectedGiftCertValue == "EMAIL") {
                    updatePersonalizationButton(product, $personalizationButton);
                    $atcButton.addClass('d-none');
                } else {
                    $personalizationButton.addClass('d-none');
                    $atcButton.removeClass('d-none');
                }
            }
        } else {
            $personalizationButton.addClass('d-none');
        } 

        if (showAddToCart && showPersonalizaton && product.customizationCode !== 'COUP') {
            $buttonDivider.removeClass('d-none');
        } else {
            $buttonDivider.addClass('d-none');
        }
    })
}

personalization.initAndUpdatePreview = function() {
    $(document).on('personalization:modalReady', function() {
        submitEnableValidation();
    });

    $(document).on('input', '.js-customization-input', function() {
        var inputIndex = $(this).index('.js-customization-input');
        var pattern = $(this).attr('pattern');

        if (pattern) {
            // Added a pattern for single character inputs in the template.  This function enforces only
            // entering alpha characters and will transform lowercase values to uppercase.
            var regex = new RegExp(pattern);
            var currentValue = $(this).val();
            var transformedValue = currentValue;

            if (pattern === '[A-Z]+') {
                transformedValue = currentValue.toUpperCase();
            }
            $(this).val(transformedValue);

            if (transformedValue && !regex.test(transformedValue)) {
                $(this).val(transformedValue.slice(0, -1));
                return; // Exit the function if the input does not match the pattern
            }
        }

        personalization.updatePreviewFromInput(this, inputIndex);
        enforceCharacterCase(this);
        submitEnableValidation();
    });

    $(document).on('change', 'input.js-style-select', function() {
        var selectedFontFace = $('input.js-style-select:checked').data('fontface');
        $('.js-preview-area').attr('data-preview-font', selectedFontFace);

        $('.js-customization-input').each(function(index) {
            personalization.updatePreviewFromInput(this, index);
            enforceCharacterCase(this); 
        });
        submitEnableValidation();
    });

    function submitEnableValidation() {
        var allInputsValid = true;
        var $inputs = $('.js-personalization-inputs .js-customization-input.required');
        $inputs.each(function() {
            if (!$(this).val()) {
                allInputsValid = false;
                return false; // Break the loop
            }
        });
        $('.js-submit-personalization, .js-save-personalization').prop('disabled', !allInputsValid);
    }

    // Updated function to enforce character case
    function enforceCharacterCase(inputElement) {
        var inputVal = $(inputElement).val();
        var allowUppercase = $(inputElement).data('allow-uppercase');
        var allowLowercase = $(inputElement).data('allow-lowercase');

        // If both cases are allowed, or none are specified, do not enforce case.
        if (allowUppercase && allowLowercase || !allowUppercase && !allowLowercase) {
            return; // Exit without enforcing case.
        }

        var newValue = inputVal;

        if (allowUppercase) {
            newValue = inputVal.toUpperCase();
        } else if (allowLowercase) {
            newValue = inputVal.toLowerCase();
        }

        $(inputElement).val(newValue);

        if (newValue !== inputVal) {
            var inputIndex = $(inputElement).index('.js-customization-input');
            personalization.updatePreviewFromInput(inputElement, inputIndex);
        }
    }
};


personalization.updatePreviewFromInput = function(inputElement, inputIndex) {
    var previewEnabled = $('.js-preview-area');
    var inputVal = $(inputElement).val();
    if (previewEnabled) {
        var basePath = $('.js-preview-area').data('svg-base-path');
        var style = $('input.js-style-select:checked').data('fontface');
        var fieldName = $(inputElement).attr('name');
        var svgPrefix;
        // Determine SVG prefix based on the field name and selected style
        if (style === 'circle-monogram') {
            switch (fieldName.toLowerCase()) {
                case 'left':
                    svgPrefix = 'left-';
                    break;
                case 'center':
                    svgPrefix = 'mid-';
                    break;
                case 'right':
                    svgPrefix = 'right-';
                    break;
                default:
                    svgPrefix = ''; // Fallback prefix if needed
            }
        } else {
            svgPrefix = $(inputElement).data('preview-case') === 'lowercase' ? 'lc-' : 'uc-';
        }

        var character = inputVal ? inputVal.toUpperCase() : ''; // Convert to uppercase for SVG filenames
        var svgPath;

        if (character) {
            svgPath = basePath + style + '/' + svgPrefix + character + '.svg';
            $('.preview-area-glyph-container').eq(inputIndex).find('img').css('opacity', '1');
        } else {
            svgPath = basePath + style + '/' + svgPrefix + $(inputElement).attr('placeholder') + '.svg';
            $('.preview-area-glyph-container').eq(inputIndex).find('img').css('opacity', '0.25'); 
        }

        if (svgPath) {
            $('.preview-area-glyph-container').eq(inputIndex).find('img').attr('src', svgPath);
        }
    }
}

personalization.editLineItemInCart = function () {
    $("body").on("click", "button.js-save-personalization", function (e) {
        e.preventDefault();

        const pid = $(this).data("pid");
        const lineItemUUID = $(this).data("uuid");
        const url = $(this).data("url");

        var personalizationDetails = {
            groups: {},
        };

        $('.js-customization-input').each(function() {
            var input = $(this);
            var groupName = input.data('input-group');
            if (!personalizationDetails.groups[groupName]) {
                personalizationDetails.groups[groupName] = {
                    inputs: {},
                    style: null
                };
            }
            personalizationDetails.groups[groupName].inputs[input.attr('name')] = {
                value: input.val(),
                lineItemLabel: input.data('line-item-label')
            };
        });
        
        $('.js-style-select:checked').each(function() {
            var selectedStyle = $(this);
            var groupName = selectedStyle.data('input-group');
            if (personalizationDetails.groups[groupName]) {
                personalizationDetails.groups[groupName].style = selectedStyle.val();
                personalizationDetails.groups[groupName].styleLabel = selectedStyle.attr('data-style-label');
            }
        });

        var form = {
            pid: pid,
            uuid: lineItemUUID,
            personalizationDetails: JSON.stringify(personalizationDetails),
        };

        $.ajax({
            url: url,
            method: 'POST',
            data: form,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            success: function (data) {
                var customizationsHtmlInner = data.customizationsHtml;
                var $cartProductCard = $('.card-body.uuid-' + data.uuid);
                $cartProductCard
                    .find('.product-customizations-container')
                    .html(customizationsHtmlInner);
                $("body").trigger('personalization:afterSuccess');
            },
            error: function (xhr, status, error) {}
        });
    });
};

personalization.hideDialog = function() {
    $('body').on('personalization:afterSuccess', function() {
        $('#personalizationModal').modal('hide');
        $('#personalizationModal').siblings().attr('aria-hidden', 'true');
    });
}

personalization.checkPreviewOverrideCases = function() {
    $(document).on('personalization:modalReady', function() {
        var $previewWindow = $('.js-preview-area');
        if ($previewWindow.length) {
            var $previewData = $('#product-personalization');
            var customCode = $previewData.data('personalization-type');
            var keywords = $previewData.data('pzln-keywords');
            // Case 1: SINI Interlock Script Override Keyword
            if (customCode === 'SINI' && keywords && keywords.includes('PZLNSINIFONTINTERLOCKING')) {
                var $scriptButton = $('.js-style-select[value="Script"]');
                $scriptButton.attr('data-fontface','interlock');
            }
        }
    });
}


module.exports = personalization;