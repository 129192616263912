'use strict';

var core = require('core/components/menu');
var headerUtils = require('core/utilities/headerUtils');
var SiteConstants = require('constants/SiteConstants');

function mobileSearchFix() {
    // Prevent iOS from scrolling to bad position on search field input focus
    $('.search-mobile .search-field').focus(event => {
        if (window.isMobile()) {
            var currentScrollPosition = $('html').scrollTop();
            setTimeout(() => {
                $('html').scrollTop(currentScrollPosition);
            }, SiteConstants.TransitionSpeed);
        }
    });
};

function dropdownMenu() {
    var isRsPure = $('.multi-brand.rspure').length > 0;
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    if (isRsPure && !window.isMobile()) {
        rsPureDesktopNav($dropdownMenu);
    } else {
        createDrawerNav($dropdownMenu);
    }
};

function createDrawerNav($dropdownMenu) {
    // Custom dropdown behaviors for top menu
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category" role="button"></li>');
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            $li.append(link);
            $closeMenu.append($('.close-menu').first().clone());
            $dropdown.children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false')
                .find('a').removeAttr('tabindex', '-1');
            $dropdown.addClass('show');
            $dropdown.parent('.dropdown-menu').addClass('overflow-hidden');
            $dropdownLink.attr('aria-expanded', 'true');
            $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            $(link).focus();
            $('.dropdown-menu').scrollTop(0);
        }
    });
}

function rsPureDesktopNav($dropdownMenu) {
    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });
    // Desktop - close menu on mouseleave
    $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });
}

function setHeaderHeightVariable() {
    var headerHeight = headerHeight = headerUtils.getHeaderHeight();
    $('html').css('--full-header-height', headerHeight + 'px');

    $('body').on('slider:init.sliderEvents', (_event, $sliderContainer) => {
        if ($sliderContainer.closest('#top-header').length) {
            headerHeight = headerUtils.getHeaderHeight();
            $('html').css('--full-header-height', headerHeight + 'px');
        }
    });
}

function navbarBackBtn() {
    $('.navbar-nav').on('click', '.back', event => {
        event.preventDefault();
        core.clearSelection(event.target);
    });
};

function navbarToggler() {
    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));
};

function toggleDrawer(status) {
    var $header = $('header');
    var $headerNav = $header.find('.header-nav');
    var headerNavHeight = $headerNav.hasClass('fixed') ? headerUtils.getHeaderHeightNavOnly() : headerUtils.getHeaderHeight();
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        $('body').addClass('mobile-menu-in');
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            .css('top', headerNavHeight)
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed)
            .css('top', headerNavHeight);
        $header
            .siblings().attr('aria-hidden', 'true');
        $navbar
            .find('.nav-link').first().focus();

    } else {
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $modalBackground.css('top', '');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

core.navbarBackBtn = navbarBackBtn;
core.mobileSearchFix = mobileSearchFix;
core.dropdownMenu = dropdownMenu;
core.setHeaderHeightVariable = setHeaderHeightVariable;
core.navbarToggler = navbarToggler;
core.toggleDrawer = toggleDrawer;

module.exports = core;
