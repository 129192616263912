'use strict';

var core = require('core/cart/cart');
var abSlider = require('core/components/slider');
var toastHelpers = require('core/components/toast');
var zonos = require('../zonos');
var Extend = window.Extend || undefined;
var base = require('core/product/base');

function triggerBonusModal() {
    var $lineItemButton = $('.bonus-product-button button');
    var $bannerContainer = $('.bonus-product-banner-container');
    var $bannerTrigger = $bannerContainer.find('.bonus-product-banner-trigger');
    var hasAvailableBonusProducts = false;

    $lineItemButton.each((_index, element) => {
        if (!$(element).closest('.bonus-product-line-item').find('.bundled-line-item .item-attributes').length) {
            hasAvailableBonusProducts = true;
        }
    });

    if (hasAvailableBonusProducts) {
        $bannerContainer.removeClass('d-none');
        if (!window.sessionStorage.getItem('bonusModalShown')) {
            $lineItemButton.trigger('click');
            // set session variable to prevent modal from showing on subsequent page loads
            window.sessionStorage.setItem('bonusModalShown', 'true');
        }

        $bannerTrigger.on('click', event => {
            event.preventDefault();
            $lineItemButton.trigger('click');
        });
    }

    // re-hide banner and remove session variable if no bonus-eligible items are left
    $('body').on('cart:update', () => {
        $lineItemButton = $('.bonus-product-button button');
        if ($lineItemButton.length === 0) {
            $bannerContainer.addClass('d-none');
            window.sessionStorage.removeItem('bonusModalShown');
        }
    });
}

function updateUpsellProducts() {
    $('body').on('cart:update', () => {
        var $upsellProducts = $('.upsell-products-slider');
        if ($upsellProducts.length > 0) {
            $.ajax({
                url: $upsellProducts.data('url'),
                type: 'get',
                success: response => {
                    var responseHtml = $.parseHTML(response);
                    var $sliderContainer = $(responseHtml).find('.slider-container');
                    $upsellProducts.find('.slider-container').remove();
                    $upsellProducts.append($sliderContainer);
                    abSlider.initializeSliders($upsellProducts);
                    zonos.updateZonosPricing();
                }
            });
        }
    });
}

/**
 * LBH Custom function to update the pricing HTML with lbhPricing data
 * @param {string} renderedPrice - The rendered price.
 * @param {object} priceObject - The price object containing additional pricing information.
 * @returns {string} The generated pricing HTML.
 */
function getPricingHtml(price) {
    var pricingHtml =
        `<div class="price">
            <span class="default-pricing">
                <span class="sales">
                    <span class="value z-price" content="${price.sales.formatted}">${price.sales.formatted}</span>

                    ${'strikethroughPrice' in price && price.strikethroughPrice.length > 0 ?
                        `<del>
                            <span class="strike-through list">
                                <span class="value z-price" content="${price.strikethroughPrice}">
                                    ${price.strikethroughPrice}
                                </span>
                            </span>
                        </del>` : ''}

                    ${'compareAtPrice' in price && price.compareAtPrice.length > 0 ?
                        `<span class="compare-at"><span class="z-price">${price.compareAtPrice}</span></span>` : ''}

                    ${'percentSaved' in price && price.percentSaved.length > 0 ?
                        `<span class="percent-saved">${price.percentSaved}</span>` : ''}
                </span>
            </span>
        </div>`;

    return pricingHtml;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    var $cart = $('.cart');
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);

    // begin LBH update to update subtotal field and add data attribute for free shipping styling
    $cart.find('.subtotal').empty().append('<span class="sub-total z-price">' + data.totals.subTotal + '</span>');
    $cart.find('.shipping-cost').empty().append('<span class="shipping-total-cost z-price">' + data.totals.totalShippingCost + '</span>').attr('data-shipping-cost', data.totals.totalShippingCost);
    // end LBH update

    $cart.find('.tax-total').empty().append('<span class="tax-total z-price">' + data.totals.totalTax + '</span>');
    $cart.find('.grand-total, .checkout-continue .checkout-btn-grand-total').empty().append('<span class="grand-total-sum z-price">' + data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted + '</span>');

    if (data.totals.giftWrapTotal?.items > 0) {
        $cart.find('.gift-wrap-total').empty().append('<span class="z-price">' + data.totals.giftWrapTotal.total + '</span>');
    } else if ($cart.find('.gift-wrap-total span').length) {
        $cart.find('.gift-wrap-total span').closest('.row').remove();
    }

    $cart.find('.checkout-continue .checkout-btn').attr('data-price', data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-footer .sub-total').empty().append('<span class="z-price">' + data.totals.subTotal + '</span>');
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $cart.find('.order-discount').removeClass('hide-order-discount');
        $cart.find('.order-discount-total').empty()
            .append('<span class="z-price">- ' + data.totals.orderLevelDiscountTotal.formatted + '</span>');
    } else {
        $cart.find('.order-discount').addClass('hide-order-discount');
    }

    // LBH update - don't show discount line if free shipping is applied
    var freeShippingMessage = $cart.find('.shipping-discount').data('free-msg');
    if (data.totals.shippingLevelDiscountTotal.value > 0 && data.totals.totalShippingCost !== freeShippingMessage) {
        $cart.find('.shipping-discount').removeClass('hide-shipping-discount');
        $cart.find('.shipping-discount-total').empty().append('<span class="order-discount-total z-price">- ' +
            data.totals.shippingLevelDiscountTotal.formatted + '</span>');
    } else {
        $cart.find('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $cart.find('.giftcertificate-discount').removeClass('d-none');
        $cart.find('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $cart.find('.giftcertificate-discount-total .amount').text(data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $cart.find('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $cart.find('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $cart.find('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    data.items.forEach(function (item) {
        var itemPrice;
        var itemTotalPrice;

        if (item.productType === 'giftCertificate') {
            itemPrice = item.priceTotal?.price;
            itemTotalPrice = item.priceTotal?.price;
        } else {
            itemPrice = getPricingHtml(item.price); // LBH update to use lbhPricing data
            itemTotalPrice = item.priceTotal?.renderedPrice;
        }

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $cart.find('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $cart.find('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $cart.find('.item-' + item.UUID).empty();
        }
        if (item.shippingSurcharge) {
            $cart.find('.surcharge-' + item.UUID).empty().append(item.shippingSurcharge)
        }
        $cart.find('.uuid-' + item.UUID + ' .unit-price').empty().append(itemPrice);
        $cart.find('.line-item-price-' + item.UUID + ' .unit-price').empty().append(itemPrice);
        $cart.find('.item-total-' + item.UUID).empty().append(itemTotalPrice);
    });
}


/**
 * Tracking adding offers to cart via cart/minicart
 */
function trackOfferAddedToCart(data) {
    Extend.trackOfferAddedToCart({
        productId: data.pid,
        productQuantity: data.quantity,
        warrantyQuantity: data.quantity,
        planId: data.extendPlanId,
        offerType: {
            area: 'cart_page',
            component: 'modal'
        }
    });
}

function renderExtendUpsellButton(uuid, pid, qty, price, category) {
    /** initialize offer */
    Extend.buttons.renderSimpleOffer('#extend-offer-' + uuid, {
        referenceId: pid.replace(/_/g, ' '),
        category: category,
        onAddToCart:
            function (plan) {
                if (plan) {
                    var form = {};
                    form.extendPlanId = plan.plan.planId;
                    form.extendPrice = plan.plan.price;
                    form.extendTerm = plan.plan.term;
                    form.pid = pid;
                    form.price = price;
                    form.category = category;
                    form.pliUUID = uuid;
                    form.quantity = qty;
                    trackOfferAddedToCart(form);

                    $.spinner().start();

                    $.ajax({
                        url: window.EXT_CART_ADDTOCART,
                        method: 'POST',
                        data: form,
                        success: function () {
                            location.reload();
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                }
            }
    });
}

/**
 * Renders an Extend upsell button in cart page
 * @param {string} uuid - line item uuid
 * @param {string} pid - corresponding product id
 * @param {string} qty- corresponding quantity
 * @returns
 */
function addExtendUpsellBtnCart(uuid, product) {
    var pid = product.pid;
    var qty = product.qty;
    var price = product.price * 100;
    var category = product.category;
    var hasExtendUpsell = $('.item-' + uuid).parents('.product-card-footer').find('#extend-offer-' + uuid).length > 0;
    var isRenderButton = $('#footercontent').find('input[name=noRenderExtendButton]').length;
    if (!hasExtendUpsell && !isRenderButton) {
        $('<div class="extend-upsell-style" id="extend-offer-' + uuid + '" data-pid=' + pid + '></div>')
            .insertAfter('.line-item-availability.availability-' + uuid)
            .ready(renderExtendUpsellButton(uuid, pid, qty, price, category));
    }
}

/**
 * Renders an Extend upsell button under Mini Cart popup
 * @param {string} uuid - line item uuid
 * @param {string} btnLabel - upsell button label
 * @param {string} pid - corresponding product id
 */
function addExtendUpsellBtnInMiniCart(uuid, product) {
    var pid = product.pid;
    var qty = product.qty;
    var price = product.price * 100;
    var category = product.category;
    var hasExtendUpsell = $('.minicart').find('.card.uuid-' + uuid).find('#extend-offer-' + uuid).length > 0;
    var isRenderButton = $('#footercontent').find('input[name=noRenderButton]').length;
    var isShippingProtecting = pid === 'EXTEND-SHIPPING-PROTECTION' ? true : false;

    if (!hasExtendUpsell && !isRenderButton && !isShippingProtecting) {
        $('<div class="extend-upsell-style" id="extend-offer-' + uuid + '" data-pid=' + pid + '></div>')
            .insertAfter('.minicart .product-summary ' + '.item-' + uuid)
            .ready(renderExtendUpsellButton(uuid, pid, qty, price, category));
    }
}

/**
 * Produces the request for render upsell buttons
 */
function makeRequestForRender(uuid, renderUpsellBtnCallback) {
    $.ajax({
        url: window.EXT_CART_WARRANTYCHECK + '?uuid=' + uuid,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            if (data.isEligible) {
                renderUpsellBtnCallback(uuid, data);
            }
        },
        error: function () { }
    });
}

/**
 * Extend config is initialized
 */
function initExtend() {
    $(document).ready(function () {
        var EXT_STORE_ID = window.EXT_STORE_ID || undefined;
        var EXT_ENVIRONMENT = window.EXT_ENVIRONMENT || undefined;
        Extend.config({ storeId: EXT_STORE_ID, environment: EXT_ENVIRONMENT });
    });
}

/**
 * Render the upsell buttons
 */
function renderUpsellBtns() {
    if (!window.EXT_CART_UPSELL_SWITCH) {
        return;
    }

    $('.cart-page .card .product-info').each(function (index, product) {
        var classes = $(product).attr('class').match(/uuid-(\w+)/);
        var uuid;

        if (classes) {
            uuid = classes[1];
        }

        makeRequestForRender(uuid, addExtendUpsellBtnCart);
    });
}

/**
 * Render the upsell buttons on mini cart
 */
function renderUpsellBtnsMiniCart() {
    if (!window.EXT_CART_UPSELL_SWITCH) {
        return;
    }

    $('.minicart .product-summary .card').each(function (index, product) {
        var classes = $(product).attr('class').match(/uuid-(\w+)/);
        var uuid;

        if (classes) {
            uuid = classes[1];
        }

        makeRequestForRender(uuid, addExtendUpsellBtnInMiniCart);
    });
}

/**
 * Rerender the button after the warranty is deleted from cart
 */
function updateUpsellBtns() {
    $('body').on('click', '.cart-delete-confirmation-btn', function () {
        $('body').on('cart:update', function () {
            renderUpsellBtns();
        });
    });

    // Render when Mini Cart loaded
    $('body').on('extend:minicart:loaded', function () {
        renderUpsellBtnsMiniCart();
    });
}

function giftWrapAction() {
    var $form = $('#giftwrapAction');
    $('body').on('click', '.js-giftwrap-remove-action-button, .js-giftwrap-add-action-button', function (e) {
        e.preventDefault();
        var productId = $('.js-giftwrap-id').val();
        var action = $('.js-giftwrap-action').val();
        var url = $form[0].action+'?productid='+productId+'&action='+action;
        $.ajax({
            url: url,
            success: function (response) {
                $('.js-giftwrap-cancel-button').trigger('click');
                if (action === 'add') {
                    toastHelpers.methods.show('success', properties.giftWrapAddedMessage, false);
                } else {
                    toastHelpers.methods.show('success', properties.giftWrapRemovedMessage, false);
                }

                location.reload();
            }
        });
        $.spinner().stop();
    });

    $('body').on('click', '.gift-wrap-action-dialog-add, .gift-wrap-action-dialog-remove', function () {
        var action = $(this).data('action');
        var productId = $(this).data('productid');
        var giftWrapType = $(this).data('giftwraptype');
        $('.js-giftwrap-id').val(productId);
        $('.js-giftwrap-action').val(action);
        if (action === 'remove') {
            $('.gift-wrap-dialog-add').addClass('d-none');
            $('.gift-wrap-dialog-remove').removeClass('d-none');
        } else {
            $('.gift-wrap-dialog-add').removeClass('d-none');
            $('.gift-wrap-dialog-remove').addClass('d-none');
            if (giftWrapType === 'free') {
                // only checking for FREE here because VIP is targeted
                // by customer group in the PD asset in the slot below
                $('.js-freegiftwrap-dialog').removeClass('d-none');
                $('.js-giftwrap-dialog').addClass('d-none');
            } else {
                $('.js-freegiftwrap-dialog').addClass('d-none');
                $('.js-giftwrap-dialog').removeClass('d-none');
            }
        }
    });
}

/**
 * Overrides AB core function to populate gift certificate form using the data from the line item
 */
function populateGiftCertificateForm() {
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', event => {
        event.preventDefault();

        var $target = $(event.target);
        var uuid = $target.data('uuid');
        var $parentContainer = $target.parents('.product-info.uuid-' + uuid);
        var recipient = $parentContainer.find('.RecipientName-' + uuid).data('value');
        var recipientEmail = $parentContainer.find('.RecipientEmail-' + uuid).data('value');
        var message = $parentContainer.find('.Message-' + uuid).data('value');
        var modal = $('#editGiftCertificateLineItemModal');

        modal.find('#recipient').attr('value', recipient.split('|')[1]);
        modal.find('#recipientEmail').attr('value', recipientEmail.split('|')[1]);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail.split('|')[1]);
        modal.find('#message').val(message.split('|')[1] || '');
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availabilityModelLevels) {
            var availabilityModelLevels = lineItem.availabilityModelLevels;
            if (availabilityModelLevels.inStock > 0) {
                messages += properties.inStockMessage;
            }
            if(availabilityModelLevels.notAvailable > 0) {
                if (availabilityModelLevels.notAvailable === lineItem.quantity) {
                    messages += '<p class="line-item-attributes-error-message">' +properties.soldOutMessage+'</p>';
                } else {
                    messages += '<p class="line-item-attributes-error-message">'+properties.remainingnotavailable1+ availabilityModelLevels.inStock +properties.remainingnotavailable2+ '</p>';
                }
            }
            if (availabilityModelLevels.preOrder > 0 || availabilityModelLevels.backOrder > 0) {
                messages += '<p class="line-item-attributes">' +properties.backOrderedMessage+ '</p>';
            }
        }
        $('.availability-' + lineItem.UUID).html(messages);
    }
}

function updateWarrantyQty(data, uuid) {
    var $lineItemContainer = $('.uuid-' + uuid);
    if ($lineItemContainer.data('warrantyuuid')) {
        var warrantyUUID = $lineItemContainer.data('warrantyuuid');
        var inputQty = $lineItemContainer.find('.quantity-stepper input').val(); // comes from quickview qty update
        var dataQty = data?.extendAnalytics?.warrantyQuantity; // comes from inline qty update
        $('.uuid-'+ warrantyUUID).find('.qty-card-quantity-count').text(dataQty || inputQty);
    }
}

function clearDefaultPromoCodeInputOnClick() {
    $(document).on('focus', 'input.js-coupon-code-field', function () {
        const $input = $(this);
        if ($input.val() === $input.data('defaultpromocode')) {
            $input.val('');
        }
    });
    $(document).on('focusout', 'input.js-coupon-code-field', function () {
        const $input = $(this);
        if (!$input.val()) {
            $input.val($input.data('defaultpromocode'));
        }
    });
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));
    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();
    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.quick-view-dialog').addClass('quick-edit');
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $.spinner().stop();
            zonos.updateZonosPricing();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

core.clientInit = function() {
    triggerBonusModal();
    if (window.EXT_GLOBAL_SWITCH) {
        initExtend();
        renderUpsellBtns();
        renderUpsellBtnsMiniCart();
    }
    giftWrapAction();
    populateGiftCertificateForm();
    updateUpsellProducts();
    clearDefaultPromoCodeInputOnClick();

    $('body').on('cart:update', (_event, data, uuid) => {
        updateWarrantyQty(data, uuid);
        triggerBonusModal();
    });

    $('#removeProductModal').on('show.bs.modal', function(e) {
        var triggeredFromRegLineItem = $(e.relatedTarget).closest('.card').hasClass('base-line-item');
        var regLineItems = $('.base-line-item').length;
        var upsellLineItems = $('.upsell-line-item').length;
        if (regLineItems === 1 && upsellLineItems > 0 && triggeredFromRegLineItem) {
            $('#removeProductModal .upsell-message').show();
        }
    }).on('hidden.bs.modal', function(e) {
        $('#removeProductModal .upsell-message').hide();
    });
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');

            sessionStorage?.setItem?.('cartcount', data.numItems);
        }

        $('.checkout-btn').addClass('disabled');
        if ($('.checkout-btn').hasClass('international-checkout')) {
            $('.international-message-info').addClass('error');
            $('.international-checkout-message p').text('We\'re sorry but the contents of your cart cannot be shipped outside of the United States. Please remove the affected items.');
        }
    } else {
        if ($('.checkout-btn').hasClass('international-checkout')) {
            if (data.isValidForInternationalCheckout) {
                $('.international-checkout-message p').text(properties.validInternationalBasket);
                $('.international-message-info').removeClass('error');
                $('.checkout-btn').removeClass('disabled');
            } else {
                $('.checkout-btn').addClass('disabled');
                $('.international-message-info').addClass('error');
                $('.international-checkout-message p').text(properties.inValidInternationalBasket);
            }
        } else {
            $('.checkout-btn').removeClass('disabled');
        }
    }
}

function init () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');

                    sessionStorage?.setItem?.('cartcount', data.basket.numItems);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    $('.uuid-' + uuid).closest('.card').remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                }

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var isMiniCart = $stepper.closest('.minicart').length > 0;
        var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
        var $select = $('.cart-page select#' + selectId);
        var value = parseInt($stepper.find('input').val());

        // if the qty change was triggered from the minicart, manually update cart qty input values
        if (isMiniCart) {
            var $cartStepper = $select.next('.quantity-stepper');
            $cartStepper.find('input').prop('value', value).prop('data-qty', value);
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', [data, uuid]);

                var isMiniCart = $('.quantity-form > .quantity').closest('.minicart').length > 0;
                if(isMiniCart) {
                    zonos.updateZonosPricing();
                }
                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    if (err.responseJSON.valid.error === true) {
                        if (err.responseJSON.valid.errorType !== "inventoryError") {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        }
                    }
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var couponCodeField = $('.coupon-code-field');

        //Trim whitespace
        couponCodeField.val(couponCodeField.val().trim());

        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!couponCodeField.val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        if(couponCodeField.data('defaultpromocode').toLowerCase() === couponCodeField.val().toLowerCase()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-form-value').text(couponCodeField.val())
            $('.duplicate-error-message').removeClass('d-none').addClass('d-block');
            $.spinner().stop();
            return false;
        }

        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        if ($('.duplicate-error-message').hasClass('d-block')) {
            $('.duplicate-error-message').removeClass('d-block');
            $('.duplicate-error-message').addClass('d-none');
        }
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    var template = `
                    <div class="alert alert-danger text-center text-wrap--balance">
                        <span class="font-size--14 p--small">
                            ${data.preErrorMessage}
                            <strong>${data.couponCode}</strong>
                            ${data.errorMessage}
                        </span>
                    </div>
                    `
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(template);
                    $('.coupon-error-message').addClass('mt-3');
                    $('body').trigger('promotion:error', data);
                    $('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if (['', null, undefined].indexOf(data.numItems) === -1 && currentItemCount !== data.numItems) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.actionUrls.showUrl);
                        }
                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        module.exports.updateCartTotals(data);
                        module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                        module.exports.validateBasket(data);
                        $('body').trigger('promotion:success', data);
                        $('.coupon-code-field').val('');
                        $.spinner().stop();
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if (['', null, undefined].indexOf(data.numItems) === -1 && currentItemCount !== data.numItems) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('shown.bs.modal', '#chooseBonusProductModal', function () {
        zonos.updateZonosPricing();
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        if (form) {
            form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
            form.pid = module.exports.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.cartModel);
                        module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        module.exports.updateAvailability(data.cartModel, form.uuid);
                        module.exports.updateProductDetails(data, form.uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        module.exports.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            }
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var parentContainer = anchor.parents('.product-info.uuid-' + uuid);
        var from = parentContainer.find('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value');
        var recipient = parentContainer.find('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value');
        var recipientEmail = parentContainer.find('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value');
        var message = parentContainer.find('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title');
        var amount = parentContainer.find('.pricing.item-total-' + uuid).data('value');

        var modal = $('#editGiftCertificateLineItemModal');
        modal.find('#from').attr('value', from);
        modal.find('#recipient').attr('value', recipient);
        modal.find('#recipientEmail').attr('value', recipientEmail);
        modal.find('#confirmRecipientEmail').attr('value', recipientEmail);
        modal.find('#message').html(message || '');
        modal.find('#amount').attr('value', (('' + amount) || '0.0').split('.')[0]);
        modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
        modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();
        $(this).off('click');
        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });
}

core.updateCartTotals = updateCartTotals;
core.initExtend = initExtend;
core.renderUpsellBtns = renderUpsellBtns;
core.updateUpsellBtns = updateUpsellBtns;
core.updateAvailability = updateAvailability;
core.validateBasket = validateBasket;
core.init = init;

module.exports = core;
