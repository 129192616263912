var main = require('integrations/main');

main.baseFiles.header = require('./components/header');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.cart = require('./cart');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.giftCertificate = require('./giftCertificate/giftCertificate').init;
main.baseFiles.base = require('./product/base');
main.baseFiles.detail = require('./product/detail');
main.baseFiles.categoryGrid = require('./components/category-grid');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.video = require('./components/video');
main.baseFiles.personalization = require('./product/personalization');
main.baseFiles.glossary = require('./product/glossary');
main.baseFiles.contactUs = require('./contactUs/contactUs');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.wishlist = require('./wishlist/wishlist');
main.baseFiles.collectionTable = require('./components/collectionTable');
main.baseFiles.cookieBot = require('./cookieBot');
main.baseFiles.search = require('./components/search').init;
main.baseFiles.zonos = require('./zonos');
main.baseFiles.modal = require('./components/modal').init;

module.exports = main;
